import "./NotAvailable.scss";


export function NotAvailable() {
    return (
        <div id="NotAvailable">
            <img src="/images/Logo.jpg" alt="Logo" id="logo" />
            <h1>Notre site est actuellement indisponible</h1>
        </div>
    );
}